/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/style.css'

window.dataLayer = window.dataLayer || []

export const onServiceWorkerUpdateReady = () => {
  // Reload window on update
  window.dataLayer.push({
    event: 'GAEvent',
    eventCategory: 'Service Worker Update',
    eventAction: 'update',
    eventLabel: 'service worker',
  })

  window.location.reload()
}
